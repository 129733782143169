import React from 'react';
import CounterChartWidget from "./components/Widgets/CounterChartWidget";
import CircularProgress from "./components/Widgets/CircularProgress";
import Typography from "./Typography";
import AssetDetails from "./Blocks/AssetDetails";
import PentestDetails from "./Blocks/PentestDetails";
import { findingStatusColors, severityColors } from './utils/constants';
import { getFormattedFindings } from './utils/helper';

const openFindingsColors = ['#F75C5C', 'var(--tertiary-600)', 'var(--secondary-700)', 'var(--primary-500)', 'var(--functional-purple)']


const data = [
    {
        "name": "critical",
        "value": 10
    },
    {
        "name": "high",
        "value": 10
    },
    {
        "name": "medium",
        "value": 25
    },
    {
        "name": "low",
        "value": 20
    }
]

const findingData = [
    {
        "name": "new",
        "value": 10
    },
    {
        "name": "existing",
        "value": 10
    },
    {
        "name": "reopened",
        "value": 25
    },
    {
        "name": "fixed",
        "value": 20
    }
]

const App = () => {
    const search = window.location.search
    const params = new URLSearchParams(search)
    let chartData = params.get("chart_data")
    chartData = JSON.parse(chartData)

    return (
        <div>
            <div className="grid grid-cols-12">
                <div className="col-span-3">
                    <div id={"charts_openFindings"} className={"bg-white p-2"}>
                        <CounterChartWidget shadow={true} className={"!m-0"} data={getFormattedFindings(chartData?.open_findings) || []}
                                            title={"Severity"}
                                            colors={severityColors}/>
                    </div>
                    <div id={"charts_findingStatus"} className={"bg-white p-2"}>
                        <CounterChartWidget shadow={true} className={"!m-0"} data={getFormattedFindings(chartData?.open_findings) || []}
                                            title={"Status"}
                                            colors={findingStatusColors}/>
                    </div>
                </div>
                <div className="col-span-3">
                </div>
            </div>
            <div className="flex">
                <PentestDetails chartData={chartData}/>
                <AssetDetails chartData={chartData}/>
            </div>
        </div>
    );
};

export default App;
