import React from 'react';
import CircularProgress from "../components/Widgets/CircularProgress";
import Typography from "../Typography";
import {statusColor, statusValue} from "../utils/constants";

const PentestDetails = ({chartData}) => {
    const asset_details = chartData?.asset_details
    return (
        <div>
            <div style={{
                background: "linear-gradient(88.04deg, rgba(18, 175, 184, 0.15) 27.58%, rgba(98, 199, 227, 0.15) 51.58%, rgba(254, 164, 43, 0.15) 93.45%)",
                width: 400,
                height: 387
            }} id={"pentest_details"}>
                <div className={"pt-7 px-7 text-center"}>
                    <div>
                        <CircularProgress radius={50} className={"m-auto"} color={"var(--secondary-700)"}
                                          value={asset_details?.testing_completed}
                                          label={`${asset_details?.testing_completed}%`}
                                          background={"var(--secondary-50)"}/>
                        <Typography className={"text-[18px] mt-3 font-semibold text-headings"}>Pentest
                            Completed</Typography>
                    </div>
                    <div>
                        <Typography
                            className={"text-[50px] font-bold text-secondary-700 leading-[100px] text-center"}>{
                                asset_details?.findings_identified
                        }</Typography>
                        <Typography className={"text-[18px] mt-3 font-semibold text-headings"}>Findings
                            Identified</Typography>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PentestDetails;
