import React from 'react';
import CircularProgress from "../components/Widgets/CircularProgress";
import Typography from "../Typography";
import {statusColor, statusValue} from "../utils/constants";
import Gauge from '../components/Widgets/Gauge';

const AssetDetails = ({chartData}) => {
    const asset_details = chartData?.asset_details
    const asset_risk = asset_details?.asset_risk_level
    const assetRiskValue = statusValue[asset_risk?.toLowerCase()]
    const assetRiskColor = statusColor[asset_risk?.toLowerCase()]

    return (
        <div>
            <div style={{
                background: "linear-gradient(88.04deg, rgba(18, 175, 184, 0.15) 27.58%, rgba(98, 199, 227, 0.15) 51.58%, rgba(254, 164, 43, 0.15) 93.45%)",
                width: 400,
                height: 387
            }} id={"asset_details"}>
                <div className={"pt-7 px-7"}>
                    <div className="flex gap-4">
                        <div className={"basis-1/2"}>
                            <CircularProgress radius={50} className={"m-auto"} color={"var(--secondary-700)"}
                                              value={asset_details?.testing_completed}
                                              label={`${asset_details?.testing_completed}%`}
                                              background={"var(--secondary-50)"}/>
                            <Typography className={"text-[18px] mt-3 font-semibold text-headings"}>Testing
                                Completed</Typography>
                        </div>
                        <div className={"basis-1/2"}>
                            <Typography
                                className={"text-[50px] font-bold text-secondary-700 leading-[100px] text-center"}>{
                                asset_details?.findings_identified
                            }</Typography>
                            <Typography className={"text-[18px] mt-3 font-semibold text-headings"}>Findings
                                Identified</Typography>
                        </div>
                    </div>
                    <div className={"text-center"}>
                        {/* <CircularProgress radius={60} className={"m-auto mt-10"} color={assetRiskColor?.[0]}
                                          value={assetRiskValue}
                                          label={asset_risk}
                                          background={assetRiskColor?.[1]}/> */}
                                          { asset_details?.asset_risk_score_ui &&  <Gauge value={asset_details?.asset_risk_score_ui} label={asset_details?.asset_risk_level_name}/> }
                                          {/* <Gauge value={10} label={"Minimal"}/> */}
                        <Typography className={"text-[18px] font-semibold text-headings"}>Asset Risk
                            Level</Typography>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default AssetDetails;
