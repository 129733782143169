import React, {memo} from 'react';
import styles from './styles.module.scss'

const Gauge = props => {
    const { value, label } = props;
    return (
        <div className={styles['gauge-container']}>
            <div className={styles['gauge-graph']}>
                <div className={styles['gauge-outer-line']}>
                    <svg className={styles.gauge} viewBox='0 0 100 100'>
                        <circle cx='50' cy='50' r='45' id={styles.darkRed}/>
                        <circle cx='50' cy='50' r='45' id={styles.red}/>
                        <circle cx='50' cy='50' r='45' id={styles.orange}/>
                        <circle cx='50' cy='50' r='45' id={styles.yellow}/>
                        <circle cx='50' cy='50' r='45' id={styles.gray}/>
                    </svg>
                    <div className={styles['center-point']}>
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_7181_16339)">
                                <circle cx="17" cy="16" r="14" fill="#F6F9FC"/>
                                <circle cx="17" cy="16" r="9" fill="#DADADA"/>
                                <circle cx="17.5" cy="15.5" r="3.5" fill="#5D5D5D"/>
                            </g>
                            <defs>
                                <filter id="filter0_d_7181_16339" x="0" y="0" width="34" height="34" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dy="1"/>
                                    <feGaussianBlur stdDeviation="1.5"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7181_16339"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7181_16339" result="shape"/>
                                </filter>
                            </defs>
                        </svg>
                    </div>
                    <div className={`${styles.indicator}`} value={value}>
                        <svg width="50" height="25" viewBox="0 0 73 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 2L71 34" stroke="#5D5D5D" stroke-width="3" stroke-linecap="round"/>
                        </svg>
                    </div>

                    <div className={styles['value-indicators']}>
                        <span className={styles['low-side']}>0</span>
                        <span className={styles['high-side']}>10</span>
                    </div>
                </div>
                <div className={styles['value-display']}>
                    <p className={`${getColorClass(value)} ${styles.value}`}>{value}</p>
                    <p className={`${getColorClass(value)} ${styles.label}`}>{label}</p>
                </div>
            </div>
        </div>
    );
};

export default Gauge;

function getColorClass(value) {
    if (value === 1 || value === 2) return styles.color12;
    if (value === 3 || value === 4) return styles.color34;
    if (value === 5 || value === 6) return styles.color56;
    if (value === 7 || value === 8) return styles.color78;
    return styles.color910;
  }
