/**
 * return number with 0 prefix for single digit numbers
 * @param number
 * @returns {string}
 */
export const get2DigitFormattedNumber = (number = 0) => {
    return (number || 0).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });
}

export const areEqualProps = (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps)
}
