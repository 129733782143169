import React, {memo} from 'react';
import {ResponsiveContainer, RadialBarChart, RadialBar, PolarAngleAxis} from "recharts";
import {areEqualProps} from "../../actions/commonActions";

const CircularProgress = ({label, value, color, textSize, className, radius = 50, background = null}) => {
    const data = [
        {name: 'L1', value}
    ];
    const HEIGHT = radius * 2
    return (
        <div className={`${className}`} style={{
            height: HEIGHT,
            width: HEIGHT,
        }}>
            <ResponsiveContainer>
                <RadialBarChart
                    width={"100%"}
                    height={"100%"}
                    innerRadius={radius - 10}
                    outerRadius={radius}
                    barSize={5}
                    data={data}
                    startAngle={90}
                    endAngle={-270}
                >
                    <circle cx={"50%"} cy={"50%"} r={radius - 10}
                            fill={background || "transparent"}
                            fillOpacity={0.5}
                    />
                    <PolarAngleAxis
                        type="number"
                        domain={[0, 100]}
                        angleAxisId={0}
                        tick={false}
                    />
                    <RadialBar
                        background
                        clockWise
                        dataKey="value"
                        cornerRadius={5}
                        fill={color || "var(--primary-500)"}
                    />
                    <text
                        x={"50%"}
                        y={"50%"}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        className={`text-[16px] font-bold capitalize`}
                        style={{
                            fill: color || "var(--primary-500)",
                            fontSize: textSize
                        }}
                    >
                        {label || value}
                    </text>
                </RadialBarChart>
            </ResponsiveContainer>
        </div>
    );
};

CircularProgress.propTypes = {};

export default memo(CircularProgress, areEqualProps);
